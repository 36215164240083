var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getAttachList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "첨부파일 설정 목록",
                  tableId: "table",
                  columnSetting: false,
                  isFullScreen: false,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  isExcelDown: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getAttachList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "신규", icon: "add" },
                                  on: { btnClicked: _vm.addAttach },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveAttach,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.deleteable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.deleteUrl,
                                    isSubmit: true,
                                    param: _vm.data,
                                    mappingType: "DELETE",
                                    label: "삭제",
                                    icon: "remove",
                                  },
                                  on: {
                                    beforeAction: _vm.deleteAttach,
                                    btnCallback: _vm.deleteCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "업무구분코드",
                              name: "taskClassCd",
                            },
                            model: {
                              value: _vm.data.taskClassCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "taskClassCd", $$v)
                              },
                              expression: "data.taskClassCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "업무구분설명",
                              name: "taskClassDesc",
                            },
                            model: {
                              value: _vm.data.taskClassDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "taskClassDesc", $$v)
                              },
                              expression: "data.taskClassDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "허용확장자",
                              name: "acceptExt",
                            },
                            model: {
                              value: _vm.data.acceptExt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "acceptExt", $$v)
                              },
                              expression: "data.acceptExt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "업로드가능파일수",
                              required: true,
                              name: "limitCnt",
                              type: "number",
                              suffix: "개",
                              maxlength: 2,
                            },
                            model: {
                              value: _vm.data.limitCnt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "limitCnt", $$v)
                              },
                              expression: "data.limitCnt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "업로드가능파일크기",
                              required: true,
                              name: "limitSize",
                              type: "number",
                              suffix: "MB",
                              maxlength: 5,
                            },
                            model: {
                              value: _vm.data.limitSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "limitSize", $$v)
                              },
                              expression: "data.limitSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "이미지 리사이즈 넓이 (0:원본)",
                              name: "resizeWidth",
                              type: "number",
                              suffix: "px",
                              maxlength: 5,
                            },
                            model: {
                              value: _vm.data.resizeWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "resizeWidth", $$v)
                              },
                              expression: "data.resizeWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "이미지 리사이즈 품질 (1:원본)",
                              name: "resizeQuality",
                              type: "number",
                              maxlength: 5,
                            },
                            model: {
                              value: _vm.data.resizeQuality,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "resizeQuality", $$v)
                              },
                              expression: "data.resizeQuality",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.ynItems,
                              label: "DragDrop허용여부",
                              name: "dragDropFlag",
                            },
                            model: {
                              value: _vm.data.dragDropFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "dragDropFlag", $$v)
                              },
                              expression: "data.dragDropFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.mselectItems,
                              label: "파일다중선택가능여부",
                              name: "multipleSelFlag",
                            },
                            model: {
                              value: _vm.data.multipleSelFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "multipleSelFlag", $$v)
                              },
                              expression: "data.multipleSelFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.ynItems,
                              label: "미리보기여부",
                              name: "previewFlag",
                            },
                            model: {
                              value: _vm.data.previewFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "previewFlag", $$v)
                              },
                              expression: "data.previewFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.ynItems,
                              label: "설명작성 가능여부",
                              name: "explainFlag",
                            },
                            model: {
                              value: _vm.data.explainFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "explainFlag", $$v)
                              },
                              expression: "data.explainFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.useFlagItems,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }